import React from 'react';
import PropTypes from 'prop-types';
import { compose } from '@shakacode/recompose';
import { Box, Icon, Typography } from '@popmenu/common-ui';
import { FormattedMessage } from 'react-intl';
import { SubmitGroup, TextFieldGroup } from '../../admin/shared/forms/BasicForm';
import BasicForm from '../../admin/shared/forms/BasicForm/BasicForm';
import MagicLinkSVG from '../../assets/svg/magic_link.svg';
import sendUserAutoLoginEmailMutation from '../../libs/gql/mutations/users/sendUserAutoLoginEmailMutation.gql';
import { notBlankValidator } from '../../utils/forms';
import { AH } from '../../consumer/shared/AccessibleHeading';

const MagicLinkForm = ({ onCompleted, sentMagicLink }) => (
  <BasicForm mutate={{
    mutation: sendUserAutoLoginEmailMutation,
    onCompleted,
    toVariables: values => ({
      ...values,
      restaurantId: null,
    }),
  }}
  >
    {sentMagicLink ? (
      <React.Fragment>
        <Box textAlign="center">
          <Icon icon={MagicLinkSVG} style={{ height: '160px', width: '160px' }} />
        </Box>
        <AH typography variant="h4" gutterBottom>
          <FormattedMessage
            id="sessions.magic_link_success_heading"
            defaultMessage="Email Sent!"
          />
        </AH>
        <Typography gutterBottom>
          <FormattedMessage
            id="sessions.magic_link_success_description"
            defaultMessage="You will receive an email if you have an account with us. Check your email for a magic link to sign back into your account in the next few minutes."
          />
        </Typography>
        <br />
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Typography>
          <FormattedMessage
            id="sessions.magic_link_description"
            defaultMessage="If you have an account with us, you&apos;ll receive a {magicLink} in your email to sign back into your My.Pop account."
            values={{
              magicLink: (
                <strong>
                  <FormattedMessage id="sessions.magic_link" defaultMessage="magic link" />
                </strong>),
            }}
          />
        </Typography>
        <br />
        <TextFieldGroup
          aria-label="Email Address"
          data-cy="email_magic_link"
          field="email"
          title="Email"
          type="email"
          validate={notBlankValidator}
        />
        <SubmitGroup
          data-cy="magic_link_submit"
          block
          ButtonProps={{ textTransform: 'capitalize' }}
          title="Send magic link"
        />
      </React.Fragment>
    )}
  </BasicForm>
);

MagicLinkForm.propTypes = {
  onCompleted: PropTypes.func.isRequired,
  sentMagicLink: PropTypes.bool.isRequired,
};

export default compose(MagicLinkForm);

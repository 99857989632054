import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from '@shakacode/recompose';
import { Button } from '@popmenu/admin-ui';
import { Box, Typography } from '@popmenu/common-ui';

import { FormattedMessage } from 'react-intl';
import { withIntl } from '../../utils/withIntl';
import BasicModal from '../../admin/shared/BasicModal';
import { closeForgotModal } from '../ModalActions';
import { withRestaurant } from '../../utils/withRestaurant';
import MagicLinkForm from '../sessions/MagicLinkForm';
import Divider from '../../admin/shared/forms/Divider';
import ForgotForm from '../sessions/ForgotForm';

const ForgotModal = ({ isAdmin, restaurant, t }) => {
  const showForgotModal = useSelector(state => state.modals.showForgotModal);
  const dispatch = useDispatch();
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const [sentMagicLink, setSentMagicLink] = useState(false);

  const closeModal = () => {
    setShowPasswordReset(false);
    setSentMagicLink(false);

    dispatch(closeForgotModal());
  };
  let title = t('sessions.forgot');

  if (isAdmin) {
    if (sentMagicLink) {
      title = '';
    } else if (showPasswordReset) {
      title = t('users.reset_password');
    } else {
      title = t('users.sign_in_help');
    }
  }
  return (
    <BasicModal
      closeModal={() => closeModal()}
      show={showForgotModal}
      size="sm"
      title={title}
    >

      {isAdmin ? (
        <React.Fragment>
          {showPasswordReset ? <ForgotForm restaurantId={restaurant ? restaurant.id : null} /> : (
            <React.Fragment>
              <MagicLinkForm
                onCompleted={() => setSentMagicLink(true)}
                sentMagicLink={sentMagicLink}
              />
              {!sentMagicLink && (
                <React.Fragment>
                  <Divider />
                  <Typography>
                    <FormattedMessage
                      id="sessions.reset_password_description"
                      defaultMessage="Want to reset your password instead?"
                    />
                  </Typography>
                  <br />
                  <Button
                    data-cy="show_reset_password_form"
                    fullWidth
                    onClick={() => setShowPasswordReset(true)}
                    textTransform="capitalize"
                    variant="secondary"
                  >
                    <FormattedMessage id="users.reset_password" defaultMessage="Reset my password" />
                  </Button>
                  <Box textAlign="center" mt={2}>
                    <Button variant="text" textTransform="capitalize" onClick={() => closeModal()}>
                      <FormattedMessage id="sessions.back_to_sign_in" defaultMessage="Back to Sign in" />
                    </Button>
                  </Box>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <ForgotForm restaurantId={restaurant ? restaurant.id : null} />
      )}
    </BasicModal>
  );
};

ForgotModal.defaultProps = {
  isAdmin: false,
  restaurant: null,
};

ForgotModal.propTypes = {
  isAdmin: PropTypes.bool,
  restaurant: PropTypes.shape({
    id: PropTypes.number,
  }),
  t: PropTypes.func.isRequired,
};

export default compose(
  withRestaurant,
  withIntl,
)(ForgotModal);
